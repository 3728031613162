/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import RouteNumber from './RouteNumber';
import { legHasCancelation } from '../util/alertUtils';

const trainException = "TRENITALIA";

const getText = (route, trip, config) => {

  function isTrainException(){
    return route.agency && route.agency.name == trainException ? true : false;
  };

  const showAgency = get(config, 'agency.show', false);
  if (route.shortName && !isTrainException()) {
    return route.shortName;
  }
  if (route.agency && route.longName && !isTrainException()) {
    return route.longName;
  }
  if (showAgency && route.agency && !isTrainException()) {
    return route.agency.name;
  }
  if (isTrainException()) { 
    let nameAndNumberOfTrain = route.shortName + " ";
    let correctCode = "";
    if(trip){
      let completeCode = trip.gtfsId.substring(trip.gtfsId.indexOf(':') + 1);
      let incorrectCode = completeCode.substring(completeCode.indexOf('-') + 1);
      correctCode = incorrectCode.substring(0, incorrectCode.indexOf("-"));
     return nameAndNumberOfTrain + correctCode;
    }
    return nameAndNumberOfTrain + correctCode;
  }
};

const RouteNumberContainer = (
  { alertSeverityLevel, className, route, trip, isCallAgency, ...props },
  { config },
) =>
  route && (
    <RouteNumber
      alertSeverityLevel={alertSeverityLevel}
      className={className}
      isCallAgency={isCallAgency || route.type === 715}
      color={route.color ? `#${route.color}` : null}
      mode={route.mode}
      agencyName={route.agency? route.agency.name : ""}
      text={getText(route, trip, config)}
      {...props}
    />
  );

RouteNumberContainer.propTypes = {
  alertSeverityLevel: PropTypes.string,
  route: PropTypes.object.isRequired,
  trip: PropTypes.object.isRequired,
  vertical: PropTypes.bool,
  className: PropTypes.string,
  fadeLong: PropTypes.bool,
};

RouteNumberContainer.defaultProps = {
  alertSeverityLevel: undefined,
  className: '',
};

RouteNumberContainer.contextTypes = {
  config: PropTypes.object.isRequired,
};

RouteNumberContainer.displayName = 'RouteNumberContainer';
export default RouteNumberContainer;

