import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from '../component/Icon';

function filterLegStops(leg, filter) {
  if (leg.from.stop && leg.to.stop && leg.trip) {
    const stops = [leg.from.stop.gtfsId, leg.to.stop.gtfsId];
    return leg.trip.stoptimes
      .filter(stoptime => stops.indexOf(stoptime.stop.gtfsId) !== -1)
      .filter(filter);
  }
  return false;
}

/**
 * Check if legs start stop pickuptype or end stop pickupType is CALL_AGENCY
 *
 * leg must have:
 * from.stop.gtfsId
 * to.stop.gtfsId
 * trip.stoptimes (with props:)
 *   stop.gtfsId
 *   pickupType
 */
export function isCallAgencyPickupType(leg) {
  return (
    filterLegStops(leg, stoptime => stoptime.pickupType === 'CALL_AGENCY')
      .length > 0
  );
}

export function isCallAgencyDeparture(departure) {
  return departure.pickupType === 'CALL_AGENCY';
}

/**
 * Checks if both of the legs exist and are taken with a rented bicycle (rentedBike === true).
 *
 * @param {*} leg1 the first leg
 * @param {*} leg2 the second leg
 */
const continueWithRentedBicycle = (leg1, leg2) =>
  leg1 != null &&
  leg1.rentedBike === true &&
  leg2 != null &&
  leg2.rentedBike === true;

/**
 * The leg mode depicts different types of leg available.
 */
export const LegMode = {
  Bicycle: 'BICYCLE',
  BicycleWalk: 'BICYCLE_WALK',
  CityBike: 'CITYBIKE',
  Walk: 'WALK',
};

/**
 * Extracts the mode for the given leg or mode.
 *
 * @param {*} legOrMode the leg or mode to extract the mode from
 * @returns LegMode, or undefined if the mode cannot be extracted
 */
export const getLegMode = legOrMode => {
  const mode =
    typeof legOrMode === 'string' || legOrMode instanceof String
      ? legOrMode
      : legOrMode && legOrMode.mode;
  switch ((mode || '').toUpperCase()) {
    case LegMode.Bicycle:
      return LegMode.Bicycle;
    case LegMode.BicycleWalk:
      return LegMode.BicycleWalk;
    case LegMode.CityBike:
      return LegMode.CityBike;
    case LegMode.Walk:
      return LegMode.Walk;
    default:
      return undefined;
  }
};

/**
 * Checks if both of the legs exist and are taken with mode 'BICYCLE'.
 *
 * @param {*} leg1 the first leg
 * @param {*} leg2 the second leg
 */
const continueWithBicycle = (leg1, leg2) =>
  getLegMode(leg1) === LegMode.Bicycle && getLegMode(leg2) === LegMode.Bicycle;

/**
 * Compresses the incoming legs (affects only legs with mode BICYCLE, WALK or CITYBIKE). These are combined
 * so that the person will be walking their bicycle and there won't be multiple similar legs
 * one after the other.
 *
 * @param {*} originalLegs an array of legs
 */
export const compressLegs = originalLegs => {
  const usingOwnBicycle =
    originalLegs[0] != null &&
    originalLegs[1] != null &&
    ((getLegMode(originalLegs[0]) === LegMode.Bicycle &&
      !originalLegs[0].rentedBike) ||
      (getLegMode(originalLegs[1]) === LegMode.Bicycle &&
        !originalLegs[1].rentedBike));
  const compressedLegs = [];
  let compressedLeg;

  forEach(originalLegs, currentLeg => {
    if (!compressedLeg) {
      compressedLeg = cloneDeep(currentLeg);
      return;
    }

    if (currentLeg.intermediatePlace) {
      compressedLegs.push(compressedLeg);
      compressedLeg = cloneDeep(currentLeg);
      return;
    }

    if (usingOwnBicycle && continueWithBicycle(compressedLeg, currentLeg)) {
      compressedLeg.duration += currentLeg.duration;
      compressedLeg.distance += currentLeg.distance;
      compressedLeg.to = currentLeg.to;
      compressedLeg.endTime = currentLeg.endTime;
      compressedLeg.mode = LegMode.Bicycle;
      return;
    }

    if (
      currentLeg.rentedBike &&
      continueWithRentedBicycle(compressedLeg, currentLeg)
    ) {
      compressedLeg.duration += currentLeg.duration;
      compressedLeg.distance += currentLeg.distance;
      compressedLeg.to = currentLeg.to;
      compressedLeg.endTime += currentLeg.endTime;
      compressedLeg.mode = LegMode.CityBike;
      return;
    }

    if (usingOwnBicycle && getLegMode(compressedLeg) === LegMode.Walk) {
      compressedLeg.mode = LegMode.BicycleWalk;
    }

    compressedLegs.push(compressedLeg);
    compressedLeg = cloneDeep(currentLeg);

    if (usingOwnBicycle && getLegMode(currentLeg) === LegMode.Walk) {
      compressedLeg.mode = LegMode.BicycleWalk;
    }
  });

  if (compressedLeg) {
    compressedLegs.push(compressedLeg);
  }

  return compressedLegs;
};

const sumDistances = legs =>
  legs.map(l => l.distance).reduce((x, y) => (x || 0) + (y || 0), 0);
const isWalkingLeg = leg =>
  [LegMode.BicycleWalk, LegMode.Walk].includes(getLegMode(leg));
const isWalkingMode = leg =>
  [LegMode.Walk, LegMode.Walk].includes(getLegMode(leg));
const isBikingLeg = leg =>
  [LegMode.Bicycle, LegMode.CityBike].includes(getLegMode(leg));

/**
 * Checks if the itinerary consists of a single walking leg.
 *
 * @param {*} itinerary the itinerary to check the legs for
 */
export const onlyWalking = itinerary =>
  itinerary.legs.length === 1 && isWalkingMode(itinerary.legs[0]);


/**
 * Checks if the itinerary consists of a single biking leg.
 *
 * @param {*} itinerary the itinerary to check the legs for
 */
export const onlyBiking = itinerary =>
  itinerary.legs.length === 1 && isBikingLeg(itinerary.legs[0]);


/**
 * Checks if any of the legs in the given itinerary contains walking.
 *
 * @param {*} itinerary the itinerary to check the legs for walking
 */
export const containsWalking = itinerary => itinerary.legs.some(isWalkingMode);


/**
 * Checks if any of the legs in the given itinerary contains biking.
 *
 * @param {*} itinerary the itinerary to check the legs for
 */
export const containsBiking = itinerary => itinerary.legs.some(isBikingLeg);

/**
 * Calculates and returns the total walking distance undertaken in an itinerary.
 * This could be used as a fallback if the backend returns an invalid value.
 *
 * @param {*} itinerary the itinerary to extract the total walking distance from
 */
export const getTotalWalkingDistance = itinerary =>
  // TODO: could be itinerary.walkDistance, but that is invalid for CITYBIKE legs
  sumDistances(itinerary.legs.filter(isWalkingLeg));

/**
 * Calculates and returns the total biking distance undertaken in an itinerary.
 *
 * @param {*} itinerary the itinerary to extract the total biking distance from
 */
export const getTotalBikingDistance = itinerary =>
  sumDistances(itinerary.legs.filter(isBikingLeg));

/**
 * Calculates and returns the total distance undertaken in an itinerary.
 *
 * @param {*} itinerary the itinerary to extract the total distance from
 */
export const getTotalDistance = itinerary => sumDistances(itinerary.legs);

/**
 * Gets the indicator color for the current amount of citybikes available.
 *
 * @param {number} bikesAvailable the number of bikes currently available
 * @param {*} config the configuration for the software installation
 */
export const getCityBikeAvailabilityIndicatorColor = (bikesAvailable, config) =>
  bikesAvailable > config.cityBike.fewAvailableCount ? '#64be14' : '#ff9000';

/**
 * Attempts to retrieve any relevant information from the leg that could be shown
 * as the related icon's badge.
 *
 * @param {*} leg the leg to extract the props from
 * @param {*} config the configuration for the software installation
 */
export const getLegBadgeProps = (leg, config) => {
  if (!leg.rentedBike || !leg.from || !leg.from.bikeRentalStation) {
    return undefined;
  }
  const { bikesAvailable } = leg.from.bikeRentalStation || 0;
  return {
    badgeFill: getCityBikeAvailabilityIndicatorColor(bikesAvailable, config),
    badgeText: `${bikesAvailable}`,
  };
};

export const getNewMinMaxCharCodes = (
  newCharCode,
  minCharCode,
  maxCharCode,
) => {
  let newMin = minCharCode;
  let newMax = maxCharCode;
  if (newMin === undefined || newMin > newCharCode) {
    newMin = newCharCode;
  }
  if (newMax === undefined || newMax < newCharCode) {
    newMax = newCharCode;
  }
  return [newMin, newMax];
};

/**
 * Retrieves all zones from the legs (from & to points) and the legs' stops.
 * This only works if zones have alphabetically continuous one letter zone names
 * and skipping a zone is not possible.
 *
 * @param {*} legs The legs to retrieve the zones from.
 */
export const getZones = legs => {
  if (!Array.isArray(legs)) {
    return [];
  }

  let minCharCode;
  let maxCharCode;
  legs.forEach(leg => {
    if (leg.from && leg.from.stop && leg.from.stop.zoneId) {
      const zoneCharCode = leg.from.stop.zoneId.charCodeAt(0);
      [minCharCode, maxCharCode] = getNewMinMaxCharCodes(
        zoneCharCode,
        minCharCode,
        maxCharCode,
      );
    }
    if (leg.to && leg.to.stop && leg.to.stop.zoneId) {
      const zoneCharCode = leg.to.stop.zoneId.charCodeAt(0);
      [minCharCode, maxCharCode] = getNewMinMaxCharCodes(
        zoneCharCode,
        minCharCode,
        maxCharCode,
      );
    }
    if (Array.isArray(leg.intermediatePlaces)) {
      leg.intermediatePlaces
        .filter(place => place.stop && place.stop.zoneId)
        .forEach(place => {
          const zoneCharCode = place.stop.zoneId.charCodeAt(0);
          [minCharCode, maxCharCode] = getNewMinMaxCharCodes(
            zoneCharCode,
            minCharCode,
            maxCharCode,
          );
        });
    }
  });

  // Add zones starting from the alphabetically first zone and ending in the alphabetically last.
  // This way zones, that are between other zones but never stopped at, will be also added.
  const zones = {};
  if (minCharCode !== undefined) {
    for (let charCode = minCharCode; charCode <= maxCharCode; charCode++) {
      zones[String.fromCharCode(charCode)] = true;
    }
  }
  return Object.keys(zones).sort();
};

export const getRoutes = legs => {
  if (!Array.isArray(legs)) {
    return [];
  }

  const routes = {};
  legs.forEach(leg => {
    if (leg.route && leg.route.agency && leg.transitLeg) {
      const { route } = leg;
      const { agency } = route;
      routes[route.gtfsId] = {
        agency: {
          fareUrl: agency.fareUrl,
          gtfsId: agency.gtfsId,
          name: agency.name,
        },
        gtfsId: route.gtfsId,
        longName: route.longName,
      };
    }
  });
  return Object.keys(routes).map(key => ({ ...routes[key] }));
};


 export function drawArrowDirection(d){
  if(d.includes("LEFT") && 
    !d.includes("SLIGHTLY_LEFT") && 
        !d.includes("HARD_LEFT")){
    return <Icon img="icon-icon_arrow-left"/>
  }
  if(d.includes("RIGHT") && 
      !d.includes("SLIGHTLY_RIGHT") && 
        !d.includes("HARD_RIGHT")){
    return <Icon img="icon-icon_arrow-right"/>
  }
  if(d.includes("CONTINUE")|| d.includes("DEPART")){
    return <Icon img="icon-icon_arrow-up"/>
  }
  if(d.includes("SLIGHTLY_LEFT")){
    return <Icon img="icon-icon_arrow-slightly-left"/>
  }
  if(d.includes("SLIGHTLY_RIGHT")){
    return <Icon img="icon-icon_arrow-slightly-right"/>
  }
  if(d.includes("HARD_LEFT")){
    return <Icon img="icon-icon_arrow-hard-left"/>
  }
  if(d.includes("HARD_RIGHT")){
    return <Icon img="icon-icon_arrow-hard-right"/>
  }
  if(d.includes("CIRCLE_COUNTERCLOCKWISE") || d.includes("CIRCLE_CLOCKWISE")){
    return <Icon img="icon-icon_arrow-circle-clockwise"/>
  }
  if(d.includes("U_TURN")){
    return <Icon img="icon-icon_arrow-u-turn"/>
  }
}

  export function getEachCorrectDirection(incorrectDirection) {
    var incompleteDirection = incorrectDirection.substring(incorrectDirection.indexOf('(') + 1);
    var completeDirectionDetail = incompleteDirection.substring(0, incompleteDirection.indexOf(")"));
    var onlyDistance = completeDirectionDetail.substring(completeDirectionDetail.indexOf('for') + 4);
    var directionWithoutDistance = completeDirectionDetail.substring(0, completeDirectionDetail.indexOf(onlyDistance));
    var floatNumber = parseFloat(onlyDistance);
    var truncedNumber = Math.trunc(floatNumber);
    var formattedDistance = truncedNumber.toString();
    var correctDirectionDetail = directionWithoutDistance + formattedDistance + "m";
    return correctDirectionDetail;
 }

 export function formatDistance(distance){
  var floatNumber = parseFloat(distance);
  var truncedNumber = Math.trunc(floatNumber);
  return truncedNumber.toString() +  " m";
 } 

function getTranslatedKeyword(address){
  if(address.includes('service road'))
    return 'strada di servizio';
  if(address.includes('path'))
    return 'percorso';
  if(address.includes('steps'))
    return 'scalinata';
  if(address.includes('sidewalk'))
    return 'marciapiede';
  if(address.includes('underpass'))
    return 'sottopasso';
  if(address.includes('parking aisle'))
    return 'area di parcheggio';
  if(address.includes('open area'))
    return 'piazzale';
  else return address;
}

 export function internationlizeDirection(correctDirectionDetail){
   var afterOn = correctDirectionDetail.substring(correctDirectionDetail.indexOf('on') + 3);
   var address = afterOn.substring(0, afterOn.indexOf("for"));

  if(document.cookie.includes("it")){
    address = getTranslatedKeyword(address);
  }

   var distance = correctDirectionDetail.substring(correctDirectionDetail.indexOf('for') + 4); 
   if(correctDirectionDetail.includes("DEPART")){
     return <FormattedMessage
           id="walkdetails-depart"
           values={{ address, distance }}
           defaultMessage=""
         />
   }
   if(correctDirectionDetail.includes("CONTINUE")){
     return <FormattedMessage
           id="walkdetails-continue"
           values={{ address, distance }}
           defaultMessage=""
         />
   }
   if(correctDirectionDetail.includes("LEFT") && 
         !correctDirectionDetail.includes("SLIGHTLY_LEFT") && 
               !correctDirectionDetail.includes("HARD_LEFT")){
     return <FormattedMessage
           id="walkdetails-left"
           values={{ address, distance }}
           defaultMessage=""
         />
   }
   if(correctDirectionDetail.includes("RIGHT") && 
       !correctDirectionDetail.includes("SLIGHTLY_RIGHT") && 
           !correctDirectionDetail.includes("HARD_RIGHT")){
     return <FormattedMessage
           id="walkdetails-right"
           values={{ address, distance }}
           defaultMessage=""
         />
   }
   if(correctDirectionDetail.includes("SLIGHTLY_RIGHT")){
     return <FormattedMessage
           id="walkdetails-slightly-right"
           values={{ address, distance }}
           defaultMessage=""
         />
   }
   if(correctDirectionDetail.includes("SLIGHTLY_LEFT")){
     return <FormattedMessage
           id="walkdetails-slightly-left"
           values={{ address, distance }}
           defaultMessage=""
         />
   }
   if(correctDirectionDetail.includes("HARD_LEFT")){
     return <FormattedMessage
           id="walkdetails-hard-left"
           values={{ address, distance }}
           defaultMessage=""
         />
   }
   if(correctDirectionDetail.includes("HARD_RIGHT")){
     return <FormattedMessage
           id="walkdetails-hard-right"
           values={{ address, distance }}
           defaultMessage=""
         />
   }
   if(correctDirectionDetail.includes("CIRCLE_COUNTERCLOCKWISE")){
     return <FormattedMessage
           id="walkdetails-cirlce-counterclockwise"
           values={{ address, distance }}
           defaultMessage=""
         />
   }
   if(correctDirectionDetail.includes("U_TURN")){
     return <FormattedMessage
           id="walkdetails-u-turn"
           values={{ address, distance }}
           defaultMessage=""
         />
   }
   else {return correctDirectionDetail;}
 }
