import PropTypes from 'prop-types';
import React from 'react';
import ComponentUsageExample from './ComponentUsageExample';


const ExternalLinkImage = ({ path, children, href, className, onClick }) =>
  (path || children !== undefined) && (
    <span className={className}>
      <span className="navi-logo">
        <a
          onClick={e => {
            e.stopPropagation();
            if (onClick) {
              onClick(e);
            }
          }}
          style={{display: "table-cell"}} href={href} target="_blank" rel="noopener noreferrer"
          >
          <img className="top-bar bp-small" alt="LogoText linkable" src={path}></img>
        </a>      
      </span>
    </span>
  );

ExternalLinkImage.propTypes = {
  path: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ExternalLinkImage.defaultProps = {
  className: '',
};

ExternalLinkImage.displayName = 'ExternalLinkImage';

ExternalLinkImage.description = () => (
  <div>
    <p>Link to external url</p>
    <ComponentUsageExample description="with text only">
      <span style={{ background: '#007ac9', padding: '10px 10px 10px 10px' }}>
        <ExternalLinkImage
          className="external-top-bar"
          path="Luguria"
          href="https://www.regione.liguria.it"
        />
      </span>
    </ComponentUsageExample>
  </div>
);

export default ExternalLinkImage;

