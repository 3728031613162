import PropTypes from 'prop-types';
import React from 'react';
import { intlShape } from 'react-intl';
import { routerShape, locationShape } from 'react-router';
import ExternalLink from './ExternalLink';
import ExternalLinkImage from './ExternalLinkImage';
import ExternalLinkImageText from './ExternalLinkImageText';
import ExternalLinkSecondImage from './ExternalLinkSecondImage';
import DisruptionInfo from './DisruptionInfo';
import Icon from './Icon';
import ComponentUsageExample from './ComponentUsageExample';
import LangSelect from './LangSelect';
import MessageBar from './MessageBar';
import CanceledLegsBar from './CanceledLegsBar';
import LogoSmall from './LogoSmall';
import { addAnalyticsEvent } from '../util/analyticsUtils';
import LoginButton from './LoginButton';
import UserInfo from './UserInfo';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

const AppBarLarge = (
  { titleClicked, logo, user },
  { router, location, config, intl },
) => {
  const openDisruptionInfo = () => {
    addAnalyticsEvent({
      category: 'Navigation',
      action: 'OpenDisruptions',
      name: null,
    });
    router.push({
      ...location,
      state: {
        ...location.state,
        disruptionInfoOpen: true,
      },
    });
  };
  
  let logoElement;
  if (config.textLogo) {
    logoElement = (
      <section className="title">
        <LogoSmall className="navi-logo" title={config.title} />
      </section>
    );
  } else {
    logoElement = <LogoSmall className="navi-logo" logo={logo} showLogo />;
  }
  
  let logoTitle;
  let logoSubTitle;
  let titleTranslated =
   <Link to="/" style={{color:"white",textDecoration:"none"}}>
      <FormattedMessage id="configtitle"/>
   </Link>

  let subtitleTranslated =<FormattedMessage
          id="configsubtitle"
  />

  function getTitleAndSubtitle(title, subtitle) {
    logoTitle = (
      <section className="title">
        <LogoSmall className="navi-logo" title={title} /> 
      </section> 
    );
    logoSubTitle = (
      <section className="subtitle">
        &nbsp; {subtitle} 
      </section>
    );
  }

  let imagesOnTopBar;

  if (config.textLogoTitle){ 
	if(config.title == "Mobimart") {
     getTitleAndSubtitle(titleTranslated, subtitleTranslated);
  } else {
    title = <div title={config.title}></div>
    subTitle = <div subtitle={config.subtitle}></div>
    getTitleAndSubtitle(title, subTitle);
  }
 }
  
  let imagelogo = (<ExternalLinkImage
  className="noborder"
  {...config.appBarLinkImage}
  onClick={e => {
    addAnalyticsEvent({
      category: 'Navigation',
      action: 'OpenServiceHomeLink',
      name: null,
    });
  }}
> 
</ExternalLinkImage> );
let imagetext = (<ExternalLinkImageText
  className="noborder"
  {...config.appBarLinkText}
  onClick={e => {
    addAnalyticsEvent({
      category: 'Navigation',
      action: 'OpenServiceHomeLink',
      name: null,
    });
  }}
> 
</ExternalLinkImageText>)

let logoSecondImage = (<ExternalLinkSecondImage
  className="noborder"
  {...config.appBarLinkSecondImage}
  onClick={e => {
    addAnalyticsEvent({
      category: 'Navigation',
      action: 'OpenServiceHomeLink',
      name: null,
    });
  }}
> 
</ExternalLinkSecondImage>)

switch(config.title){
  case 'Liguria': 
    imagesOnTopBar =  [imagelogo, imagetext, logoSecondImage];
    break;
  case 'Mobimart':
    imagesOnTopBar = <div><span style={{display:"inline-block"}}>{imagelogo}</span> <span style={{display:"inline-block"}}>{logoTitle}</span>  </div>;
    break;
  default: 
    imagesOnTopBar = <button
    className="noborder"
    onClick={e => {
      titleClicked(e);
      addAnalyticsEvent({
        category: 'Navigation',
        action: 'Home',
        name: null,
      });
    }}
    >
    {logoElement} {logoTitle} {logoSubTitle}
    </button>
    break;
}

  /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */
  return (
    <div>
      <div className="top-bar bp-large flex-horizontal">
 
     {imagesOnTopBar}

        <div className="empty-space flex-grow" />
        {config.showLogin &&
          (!user.name ? (
            <LoginButton />
          ) : (
            <UserInfo
              user={user}
              list={[
                {
                  key: 'dropdown-item-1',
                  messageId: 'logout',
                  href: '/logout',
                },
              ]}
            />
          ))}


        <div class="empty-space flex-grow"></div>

        <div className="navi-languages right-border navi-margin">
          <LangSelect />
        </div>
        {config.showCaution  ? (
        <div className="navi-icons navi-margin padding-horizontal-large">
          <a
            className="noborder"
            onClick={openDisruptionInfo}
            aria-label={intl.formatMessage({
              id: 'disruptions',
              defaultMessage: 'Disruptions',
            })}
          >
            <Icon img="icon-icon_caution" className="caution-topbar" />
          </a>
        </div>
        ) : (<div></div>)}
        <div className="padding-horizontal-large navi-margin">
          <ExternalLink
            className="external-top-bar"
            {...config.appBarLink}
            onClick={() => {
              addAnalyticsEvent({
                category: 'Navigation',
                action: 'OpenServiceHomeLink',
                name: null,
              });
            }}
          />
        </div>
      </div>
      <MessageBar />
      <DisruptionInfo />
      <CanceledLegsBar />
    </div>
  );
};

AppBarLarge.propTypes = {
  titleClicked: PropTypes.func.isRequired,
  logo: PropTypes.string,
  user: PropTypes.object,
};

AppBarLarge.defaultProps = {
  logo: undefined,
};

AppBarLarge.displayName = 'AppBarLarge';

AppBarLarge.contextTypes = {
  router: routerShape.isRequired,
  location: locationShape.isRequired,
  config: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

AppBarLarge.description = () => (
  <div>
    <p>AppBar of application for large display</p>
    <ComponentUsageExample description="">
      <AppBarLarge titleClicked={() => {}} />
    </ComponentUsageExample>
  </div>
);

export { AppBarLarge as default, AppBarLarge as Component };


