import PropTypes from 'prop-types';
import React from 'react';
import ComponentUsageExample from './ComponentUsageExample';

const ExternalLinkSecondImage = ({ path, children, href, className, onClick }) =>
  (path || children !== undefined) && (
    <span className={className}>
      <span className="navi-logo">
        <a
          onClick={e => {
            e.stopPropagation();
            if (onClick) {
              onClick(e);
            }
          }}
          style={{display: "table-cell"}} href={href} 
          >
          <img className="top-bar bp-small" alt="LogoText linkable" src={path}></img>
        </a>      
      </span>
    </span>
  );

ExternalLinkSecondImage.propTypes = {
  path: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ExternalLinkSecondImage.defaultProps = {
  className: '',
};

ExternalLinkSecondImage.displayName = 'ExternalLinkSecondImage';

ExternalLinkSecondImage.description = () => (
  <div>
    <p>Link to external url</p>
    <ComponentUsageExample description="with text only">
      <span style={{ background: '#007ac9', padding: '10px 10px 10px 10px' }}>
        <ExternalLinkImageText
          className="external-top-bar"
          path="Liguria"
          href="http://interreg-maritime.eu/web/mobimart"
        />
      </span>
    </ComponentUsageExample>
  </div>
);

export default ExternalLinkSecondImage;

